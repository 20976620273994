<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/FU_Xojayev.jpg"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Хўжаев Файзулла Губайдуллаевич <br /></p
          ></b-card-text>
        </b-col>
        <b-col md="6">
           <b-card-text>
          <p class="TextBlack17">
            Илмли, ўз замонасининг билимдони, сиёсатдон ва маданиятли, ўз
            тарихини яхши билган инсон бўлган Файзулла Хўжаев савдогар оиласида
            туғилганлиги учун ҳар томонлама яхши таъминланган еди. У отасидан
            қолган бой- ликни жадид мактаблари очишга, ёш бухороликлар
            фаолиятини моддий жиҳатдан қўллаб-қувватлашга сарф лади. 1917 йилдан
            бошлаб Файзулла Хўжаев ва Абдурауф Фитрат ёш бухороликлардан ажралиб
            чиққан «Сўл қанот»га бошчилик қилишади. Файзулла Хўжаев жадидчилар
            билан биргаликда Бухорода конституцион монархия, сўнгра демо- кратик
            республика тузиш учун курашдилар. Файзулла Хўжаев бой илмий мерос
            қолдирган. Унинг «Бухоро инқилобининг тарихига материаллар» (1926),
            «Бухородаги революция ва Ўрта Осиёнинг миллий чегараланиш тарихига
            доир» (1932) китоблари, «БХСРдаги иқтисодий аҳвол» (1924), «Ёш
            бухороликлар тўғрисида» (1926), «Жадидлар» (1926) каби мақолаларида
            ХХ асрнинг 1 чорагидаги жиддий муаммолар ҳақидаги фикрлари баён
            етилган. Файзулла Хўжаев ҳаёти ва фаолияти мустақиллик туфайли
            чуқурроқ ўрганила бошланди. 1996 йилда ўзбек халқи ўзининг фидоий
            фарзанди Файзулла Хўжаевнинг 100 йиллик юбилейини нишонлади. Юбилей
            муносабати билан Файзулла Хўжаев асарлари ва у ҳақидаги тадқиқотлар
            нашр етилди. Ўзбек миллатининг асл фарзанди Файзулла Хўжаев 1896
            йилда Бухоро хонлиги даврида Бухорода йирик савдогар оиласида дунёга
            келади. Унинг отаси Убайдуллахўжа Қосимхўжаев Москвада хусусий савдо
            маҳкамасига ега бўлиб, Россиянинг ўзида ҳамда хорижий бозорларда
            қоракўл тери билан кенг миқёсда савдо-сотиқ юргизар еди. У келажакда
            ўғлини ўзига ўхшаган еътиқодли, илмли, мусулмон бўлиб йетишишини
            истар еди. Файзулла Хўжаев 1913 йилда жадидлар ҳаракатига қўшилади.
            Бу ҳаракат Бухоро шароитида маълум тараққиёт аҳамиятига ега еди. Ёш
            Файзуллада тараққийпарвар ғояларнинг шаклланишида Бухоронинг
            дастлабки маърифатпарварларидан бири Абдулвоҳид Бухоновнинг
            сезиларли таъсири бўлган. Бухорода демократик ҳаракат аҳоли ўртасида
            маърифат ва маданият тарқатиш учун курашдан бошланади. Бу янги усул
            мактаблари (мактаби усули жадиди) очишдан бошланди. Мазкур
            мактабларда диний таълим асослари билан бир қаторда математика,
            география, табиацҳунослик каби дунёвий ва ижтимоий фанлар ҳам
            ўқитилади. Янги усул мактаблар Бухорода аввалдан мавжуд бўлган
            мактабларга (мактаби усули қадими)га қарама-қарши йўсинда ташкил
            етилади. Миллий зиёлилар ва маҳаллий ҳукуматнинг маориф- ни ислоҳ
            қилиш ҳаракатига йетакчилик қилган айрим вакил- лари мавжуд тузумга
            ва Бухородаги мустабид бошқарув усулига танқидий назар билан
            қараганларнинг барчасини янги усул мактаблари атрофига бирлаштира
            бошлади. Бухоро ўша йиллари мусулмон динининг бешиги, ислом динининг
            таянчи ҳисобланар еди. Шаҳардаги 364 енсиз ва тор кўчаларда 360
            мачит ва ҳаммаси бўлиб 20 мингга яқин талабага ега бўлган 138 мактаб
            ва мадраса бўлиб, талаба- ларнинг аксари кўпчилиги руҳоний,
            савдогар, амир амалдорлари ва бой табақа вакилларининг болалари еди.
            Файзулла Хўжаев ўз мамлакатининг иқтисодий, ижтимоий-сиёсий ва
            маданий соҳалардаги қолоқлигига барҳам бериш, халқ оммасининг
            турмушини яхшилаш йўлларини қидирди. Бу борада Файзулла Хўжаев ва ёш
            бухороликлар ҳам бир масалада - Бухорони тараққиёт йўлига олиб чиқиш
            масаласида бир хил фикрда едилар. У, жадидлар орасидан ўз ғояларига
            тарафдорларни ҳам топди ва улардан бошқа сафдошлари билан
            биргаликда, 1917 йилда Ёш бухороликлар партиясини тузди. 1917 йил
            апрелда Ёш бухорликлар Файзулла Хўжаев бошчилигида халққа баъзи бир
            демократик еркинликларни бериш кераклиги тўғрисидаги талаблар билан
            амирга мурожаат қилдилар. Аммо амир ёш бухороликларга ён босмади,
            улардан қаттиқ ўч олди. 1918 йилда вужудга келган Бухоро Коммунистик
            партияси халқ оммасининг туб манфатларини ифодалар еди. Файзулла
            Хўжаев халқ ишига шижоат билан киришиб ҳормай-толмай иш олиб бориб,
            БХСР (Бухоро Халқ Совет Республикаси) Халқ Нозирлари Советининг
            раиси лавозимида ҳаракат қилди. Файзулла Хўжаев 1920 йил январда
            Тошкентда ташкил топган инқилобчи ёш бухороликлар партияси Марказий
            бюроси раиси бўлиб ишлай бошлади. 1920 йил 14 сентябрда Бухоро халқ
            Нозирлар кенгаши тузилди. Файзулла Хўжаев ташқи ишлар нозири
            лавозимида ишлай бошлади. Бухоро Республикаси давлат арбоби бўлган
            Файзулла Хўжаев биринчи кунданоқ мустақил сиёсат юргазишга, Россия
            ва хорижий давлатлар билан тенг ҳуқуқли алоқалар ўрнатишга ҳаракат
            қилди. У ўзбек халқининг фарзандлари илм олиши йўлида анча ишларни
            амалга оширади. Талабаларни хориж мамлакатларига жўнатиб ўқитиш
            ташаббуси билан чиққан. Файзулла Хўжаев раҳбарлигида 1923 йилдаёқ
            аҳолига медицина ҳизмати кўрсатиш, умумий таълим мактаблари, билим
            юртлари ва курслар тармоғини кенгайтириш соҳасида анчагина амалий
            чоралар кўрилди. Аҳолига - ишчилар, хизматчилар, ҳунармандлар,
            йерсиз ва кам йерли деҳқонларга давлат ҳисобидан медицина хизмати
            кўрсатиш, меҳнат, ижтимоий таъминот ҳамда соғлиқни сақлаш нозирлиги
            томонидан йўлга қўйилди. Ески Бухорода 200 ўринлик дастлабки шаҳар
            касалхонаси, 12 та даволаш-амбулатория пункти очилди. Халқ маорифи
            ҳам анча ривожланди. 1923 йилда Бухоро республикасида 32 та
            бошланғич ва ўрта мактаб, тўртта мусиқа мактаби, иккита педагоглар
            тайёрлаш институти, 11 та болалар уйи, ҳунар мактаби ташкил етилди.
            Аммо 1937 йил 9 июлда Файзулла Хўжаев Москвада қамоққа олинди. Унга
            «Ўнг троцкийчи блок» фаолиятига қўшилганлиги, «Миллий иттиҳод»
            ташкилоти фаолиятига раҳбарлик қилганлик, «босмачилик» ҳаракати
            қўрбошиларини қўллаб-қувватлаганлик, Фитрат, Чўлпон, Абдулла
            Қодирий, Беҳбудий ва бошқа ўзбек зиёлиларига ғамхўрлик кўрсат-
            ганлик каби айблар қўйилган ҳолда отишга ҳукм қилинган. Шу тариқа
            ўзбек халқининг оташ қалб фарзанди Файзулла Хўжаев қатағонлар
            қурбонига айланган.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3">

        </b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
       
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
